import React from 'react'
import './hero.css'

const Hero = () => {
  return (
    <div className="hero-image">
      <div style={{ height: '100%', width: '100%', position: 'absolute' }}>
        <div className="hero-text d-flex align-items-center" style={{ minHeight: '100%' }}>
          <div className="container">
            <div className="mx-auto text-center">
              <h1 className="logo animate__animated animate__fadeIn animate__slower">
                <span>atid</span>
                <span style={{ color: 'black', textShadow: '#ffffff -1px -1px 0px, #ffffff -1px 1px 0px, #ffffff 1px 1px 0px, #ffffff 1px -1px 0px' }}>o</span>
              </h1>
              <p className="slogan">
                <span class="mx-2 animate__animated animate__fadeIn animate__delay-1s">conseil</span>
                <span class="mx-2 animate__animated animate__fadeIn animate__delay-2s">confiance</span>
                <span class="mx-2 animate__animated animate__fadeIn animate__delay-3s">proximité</span>
              </p>
              <p className="slogan mt-5">
                <span class="mx-2 text-white animate__animated animate__fadeIn animate__delay-4s">prochainement...</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
