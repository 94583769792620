import 'animate.css/animate.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import '../components/global.css'
import '../components/variables.css'
import Seo from './seo'

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <>
        <Seo />
        <main>{children}</main>
      </>
    )
  }
}

export default Layout
